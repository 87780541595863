
import { defineComponent, PropType } from "vue";
import { WeatherInfo } from "@/interfaces/WeatherInfo";

export default defineComponent({
  name: "WeatherLocation",
  props: {
    weatherInfo: {
      type: Object as PropType<WeatherInfo>,
      required: true,
    },
  },
  computed: {
    feelsLikePhrase() {
      return `Feels like ${this.weatherInfo.temperatureFeelsLike}${this.weatherInfo.temperatureUnits}`;
    },
    cloudsPhrase() {
      return capitalizeFirstLetter(this.weatherInfo.cloudsDescription);
    },
    windPhrase() {
      return capitalizeFirstLetter(this.weatherInfo.windSpeedDescription);
    },
    windDirectionIconStyle() {
      const ICON_ROTATED_BY_DEFAULT = 45; // 45° clockwise for FA icon

      const transformRotate =
        this.weatherInfo.windDirection - ICON_ROTATED_BY_DEFAULT;

      return {
        transform: `rotate(${transformRotate}deg)`,
      };
    },
    formattedVisibility() {
      return (this.weatherInfo.visibility / 1000).toFixed(1);
    },
  },
});

function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}
