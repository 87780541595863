
import { defineComponent } from "vue";

export default defineComponent({
  name: "SettingsButton",
  props: {
    inSettingsMode: {
      type: Boolean,
      required: true,
    },
  },
  emits: {
    open: null,
    close: null,
  },
  computed: {
    action() {
      return this.inSettingsMode ? this.close : this.open;
    },
  },
  methods: {
    open() {
      this.$emit("open");
    },
    close() {
      this.$emit("close");
    },
  },
});
