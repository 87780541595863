
import { defineComponent, PropType } from "vue";
import { WeatherInfo } from "@/interfaces/WeatherInfo";
import AddLocation from "@/components/AddLocation.vue";
import { CityInfo } from "@/interfaces/CityInfo";
import draggable from "vuedraggable";

export default defineComponent({
  name: "WeatherSettings",
  props: {
    locations: {
      type: Object as PropType<Array<WeatherInfo>>,
      required: true,
    },
  },
  emits: {
    // eslint-disable-next-line prettier/prettier, @typescript-eslint/no-unused-vars
    remove(payload: WeatherInfo) {
      return true;
    },
    // eslint-disable-next-line prettier/prettier, @typescript-eslint/no-unused-vars
    add(city: CityInfo) {
      return true;
    },
    // eslint-disable-next-line prettier/prettier, @typescript-eslint/no-unused-vars
    error(e: string) {
      return true;
    },
    // eslint-disable-next-line prettier/prettier, @typescript-eslint/no-unused-vars
    reorder(locations: Array<WeatherInfo>) {
      return true;
    },
  },
  data() {
    return {
      draggableLocations: [] as Array<WeatherInfo>,
      drag: false,
    };
  },
  methods: {
    remove(location: WeatherInfo) {
      this.$emit("remove", location);
    },
    add(city: CityInfo) {
      this.$emit("add", city);
    },
    error(e: string) {
      this.$emit("error", e);
    },
    onDragStart() {
      this.drag = true;
    },
    onDragEnd() {
      this.drag = false;
      this.$emit("reorder", this.draggableLocations);
    },
  },
  watch: {
    locations: {
      handler() {
        this.draggableLocations = [...this.locations];
      },
      deep: true,
    },
  },
  components: {
    draggable,
    AddLocation,
  },
});
