import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "weather-widget" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_settings_button = _resolveComponent("settings-button")!
  const _component_weather_settings = _resolveComponent("weather-settings")!
  const _component_weather_location = _resolveComponent("weather-location")!
  const _component_no_locations_tip = _resolveComponent("no-locations-tip")!
  const _component_loading_spinner = _resolveComponent("loading-spinner")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_settings_button, {
      "in-settings-mode": _ctx.inSettingsMode,
      onClose: _ctx.closeSettings,
      onOpen: _ctx.openSettings
    }, null, 8, ["in-settings-mode", "onClose", "onOpen"]),
    _withDirectives(_createVNode(_component_weather_settings, {
      locations: _ctx.locations,
      onRemove: _cache[0] || (_cache[0] = (location) => _ctx.handleRemove(location)),
      onAdd: _cache[1] || (_cache[1] = (city) => _ctx.handleAdd(city)),
      onReorder: _cache[2] || (_cache[2] = (locations) => _ctx.handleReorder(locations)),
      onError: _cache[3] || (_cache[3] = (e) => _ctx.displayError(e))
    }, null, 8, ["locations"]), [
      [_vShow, _ctx.inSettingsMode]
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.locations, (location) => {
      return _withDirectives((_openBlock(), _createBlock(_component_weather_location, {
        "weather-info": location,
        key: location.name
      }, null, 8, ["weather-info"])), [
        [_vShow, !_ctx.inSettingsMode]
      ])
    }), 128)),
    (_ctx.locations.length === 0 && !_ctx.inSettingsMode)
      ? (_openBlock(), _createBlock(_component_no_locations_tip, {
          key: 0,
          onOpen: _ctx.openSettings
        }, null, 8, ["onOpen"]))
      : _createCommentVNode("", true),
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_loading_spinner, { key: 1 }))
      : _createCommentVNode("", true),
    (_ctx.error)
      ? (_openBlock(), _createElementBlock("div", {
          key: 2,
          class: "weather-widget__error",
          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.error = ''))
        }, _toDisplayString(_ctx.error), 1))
      : _createCommentVNode("", true)
  ]))
}