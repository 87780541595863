import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@fortawesome/fontawesome-free/svgs/solid/xmark.svg'
import _imports_1 from '@fortawesome/fontawesome-free/svgs/solid/gear.svg'


const _hoisted_1 = {
  key: 0,
  src: _imports_0,
  class: "icon",
  alt: "Close Settings"
}
const _hoisted_2 = {
  key: 1,
  src: _imports_1,
  class: "icon",
  alt: "Open Settings"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: "weather-widget__settings-button",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.action && _ctx.action(...args)))
  }, [
    (_ctx.inSettingsMode)
      ? (_openBlock(), _createElementBlock("img", _hoisted_1))
      : (_openBlock(), _createElementBlock("img", _hoisted_2))
  ]))
}