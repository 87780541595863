
import { defineComponent } from "vue";

export default defineComponent({
  name: "LoadingSpinner",
  data() {
    return {
      isDayTime: true,
    };
  },
  mounted() {
    const hours = new Date().getHours();

    this.isDayTime = hours > 6 && hours < 20;
  },
});
